import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, StarFilled, StarOutlined } from '@ant-design/icons';

import Button from 'components/Button';
import { returnField, getToken } from 'services/utils';

import styles from './Card.module.scss';
import { ICardProps, ICardType } from './Card.types';
import { CardWrapper, IconWrapper, ParagraphWrapper } from './Card.styled';

const Card: FC<ICardProps> = ({
  card,
  cardType,
  onAddFavorites = () => {},
  setIsSubscribingCards = () => {},
}) => {
  const { t } = useTranslation('applications');

  const {
    is_favorite: isFavorite,
    description_ru: descriptionRu,
    description_en: descriptionEn,
    name_ru: nameRu,
    name_en: nameEn,
    is_available: isUse,
    id,
    logo,
    url,
    auth_url: authUrl,
  } = card;
  const redirectUrl = authUrl ? `${authUrl}${getToken()}` : url;
  // TODO: It will be necessary in the future.
  /* const menu = (
    <Menu>
      <Menu.Item key="0">
        <p className={styles.card__dropdownItem}>Сделать стартовой страницей</p>
      </Menu.Item>
    </Menu>
  ); */

  const addToFavorites = (e: React.MouseEvent) => {
    e.preventDefault();
    onAddFavorites(id);
  };

  return (
    <CardWrapper
      href={cardType === ICardType.own ? redirectUrl : undefined}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.card__header}>
        <div className={styles.card__info}>
          <div className={styles.card__image}>{logo && <img src={logo} alt="Logo" />}</div>
          <h2 className={styles.card__title}>{returnField(nameRu, nameEn)}</h2>
        </div>
        <div className={styles.card__extra}>
          {cardType === ICardType.own && (
            <>
              <Button onClick={addToFavorites} className={styles.card__button} buttonType="text">
                {isFavorite ? (
                  <StarFilled className={styles.card__star_fill} />
                ) : (
                  <IconWrapper>
                    <StarOutlined className={styles.card__star} />
                  </IconWrapper>
                )}
              </Button>
              {/* TODO: It will be necessary in the future.
              <Dropdown overlay={menu} trigger={['click']} placement="topRight" arrow>
                <IconWrapper>
                  <EllipsisOutlined className={styles.card__ellipsis} />
                </IconWrapper>
              </Dropdown> */}
            </>
          )}
          {cardType === ICardType.store && (
            <>
              {isUse ? (
                <p className={styles.card_isUsed}>
                  <CheckOutlined />
                  {t('card.use')}
                </p>
              ) : (
                <Button danger onClick={() => setIsSubscribingCards(card)}>
                  {t('card.plug')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <ParagraphWrapper>
        <p className={styles.card__description}>{returnField(descriptionRu, descriptionEn)}</p>
      </ParagraphWrapper>
    </CardWrapper>
  );
};

export default Card;
