import { IState } from 'store/interfaces';

import { IUserState } from './types';

const getUserState = (state: IState): IUserState => state.userReducer;

export const getAuth = (state: IState) => {
  const userState = getUserState(state);
  return userState.isAuthorized;
};

export const getPersonInfo = (state: IState) => {
  const userState = getUserState(state);
  return userState.user;
};
