import { AxiosResponse } from 'axios';

import agent from 'agent';
import { ICard, ITag } from 'components/Card/Card.types';

export const getListTags = (): Promise<AxiosResponse<ITag[]>> => {
  return agent.GET('api/products/tags/');
};

export const getListProducts = (): Promise<AxiosResponse<ICard[]>> => {
  return agent.GET('api/products/');
};
