import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from 'components/Avatar';
import Logo from 'components/Logo/Logo';
import { MyApplicationsIcon, AllApplicationsIcon, DarkModeIcon, LightModeIcon } from 'assets/icons';
import { Paths } from 'router/constants';
import { useColorScheme } from 'services/hook/useColorScheme';
import { getColorMode } from 'store/card/getters';
import { setUserInfoAction } from 'store/user/actions';
import { getPersonInfo } from 'store/user/getters';
import { getUserInfo } from 'services/UserService';
import Language from 'components/Language';
import Burger from 'components/Burger';
import NavMobile from 'components/NavMobile';

import styles from './Header.module.scss';

const Header: FC = () => {
  const { t } = useTranslation('header');
  const { toggleTheme } = useColorScheme();
  const colorScheme = useSelector(getColorMode);

  const person = useSelector(getPersonInfo);
  const dispatch = useDispatch();

  const [isMenuOpened, setMenuOpened] = useState<boolean>(false);
  const toggleMenu = useCallback(() => {
    setMenuOpened((prev) => !prev);
  }, []);

  const getUser = useCallback(async () => {
    if (!person) {
      try {
        const { data } = await getUserInfo();
        dispatch(setUserInfoAction(data));
      } finally {
        // TODO: add loading
      }
    }
  }, [person, dispatch]);

  useEffect(() => {
    if (!person) getUser();
  }, [getUser, person]);

  return (
    <div className={styles.header}>
      <div className={styles.header__logo}>
        <Link to={Paths.home}>
          <Logo />
        </Link>

        <div>
          <Switch
            checkedChildren={<DarkModeIcon />}
            unCheckedChildren={<LightModeIcon />}
            defaultChecked={colorScheme === 'light'}
            onChange={() => toggleTheme()}
            className={styles.switchTheme}
          />
          <Burger onToggleNav={toggleMenu} />
        </div>
      </div>
      <div className={styles.header__nav}>
        <NavLink
          exact
          activeClassName={styles.header__link_active}
          className={styles.header__link}
          to={Paths.home}
        >
          <MyApplicationsIcon /> {t('myApps')}
        </NavLink>
        <NavLink
          exact
          activeClassName={styles.header__link_active}
          className={styles.header__link}
          to={Paths.store}
        >
          <AllApplicationsIcon /> {t('allApps')}
        </NavLink>
        <Language className={styles.header__lang} />
        <Avatar person={person} />
      </div>
      <NavMobile isVisible={isMenuOpened} onClose={() => setMenuOpened(false)} person={person} />
    </div>
  );
};

export default Header;
