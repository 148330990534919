import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import React, { FC } from 'react';

import styles from './Burger.module.scss';
import { BurgerProps } from './Burger.types';

const Burger: FC<BurgerProps> = ({ onToggleNav }) => {
  return (
    <button className={styles.burgerButton} type="button" onClick={onToggleNav}>
      <MenuOutlined />
    </button>
  );
};

export default Burger;
