import React, { FC } from 'react';

import { LOGO_DARK_SRC } from '../../constants/common';

import styles from './Logo.module.scss';

const Logo: FC = () => {
  return (
    <div className={styles.logoContainer}>
      <img src={LOGO_DARK_SRC} alt="Logo" className={styles.logoImage} />
    </div>
  );
};

export default Logo;
