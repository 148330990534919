import { Dispatch, SetStateAction } from 'react';

export interface ITag {
  name_ru: string;
  name_en: string;
}

export interface ICard {
  id: number;
  name_ru: string;
  name_en: string;
  description_ru: string;
  description_en: string;
  isUse: boolean;
  is_favorite: boolean;
  is_available: boolean;
  tags: ITag[];
  filters: string[];
  html_content_ru: string;
  html_content_en: string;
  logo: string;
  url: string;
  auth_url?: string;
}

export enum ICardType {
  own = 'own',
  store = 'store',
}

export type ICardProps = {
  card: ICard;
  cardType: ICardType;
  onAddFavorites?: (id: number) => void;
  setIsSubscribingCards?: Dispatch<SetStateAction<ICard | null>>;
};
