import styled from 'styled-components';

export const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.primary};
  transition: all 0.25s linear;
`;

export const LayoutWrapper = styled.div`
  background: ${({ theme }) => theme.backgroundLayout};
`;
