import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import CardLayout from 'layouts/CardLayout';
import MainLayout from 'layouts/MainLayout';
import Card from 'components/Card';
import { ICard, ICardType } from 'components/Card/Card.types';
import { ParagraphWrapper } from 'components/Card/Card.styled';
import { setCardsAction } from 'store/card/actions';
import { getCards as getDefaultCards } from 'store/card/getters';
import { getListProducts } from 'services/CardService';
import { getCardsWithLocaleTags, returnField } from 'services/utils';

import { ModalWrapper } from './StoreAppsPage.styled';
import styles from './StoreAppsPage.module.scss';

const StoreAppsPage: FC = () => {
  const { t } = useTranslation('applications');

  const [cards, setCards] = useState<ICard[]>([]);
  const defaultCards = useSelector(getDefaultCards);
  const dispatch = useDispatch();
  const [isSubscribingCard, setIsSubscribingCards] = useState<ICard | null>(null);

  const getContent = () => {
    return {
      __html: isSubscribingCard
        ? returnField(isSubscribingCard.html_content_ru, isSubscribingCard.html_content_en)
        : '',
    };
  };

  const getCards = useCallback(async () => {
    try {
      const { data } = await getListProducts();
      const dataLocale = getCardsWithLocaleTags(data);
      setCards(dataLocale);
      dispatch(setCardsAction(data));
    } catch {
      setCards([]);
      dispatch(setCardsAction([]));
    }
  }, [dispatch]);

  const handleCancel = () => {
    setIsSubscribingCards(null);
  };

  useEffect(() => {
    setCards(getCardsWithLocaleTags(defaultCards));
  }, [defaultCards]);

  useEffect(() => {
    getCards();
  }, [getCards]);

  return (
    <MainLayout cards={cards} setCards={setCards} hasTags>
      <CardLayout name={t('titles.allApps')}>
        {cards.map((item, index) => (
          <Card
            setIsSubscribingCards={setIsSubscribingCards}
            cardType={ICardType.store}
            key={String(index)}
            card={item}
          />
        ))}
        <ModalWrapper
          title={isSubscribingCard?.name_ru}
          visible={!!isSubscribingCard}
          onCancel={handleCancel}
          footer={[
            <Button type="primary" key="back" onClick={handleCancel}>
              <span className={styles.modal__button}>Ok</span>
            </Button>,
          ]}
        >
          <ParagraphWrapper>
            <p dangerouslySetInnerHTML={getContent()} className={styles.modal__description} />
          </ParagraphWrapper>
        </ModalWrapper>
      </CardLayout>
    </MainLayout>
  );
};

export default StoreAppsPage;
