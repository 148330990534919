import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import { useDispatch } from 'react-redux';

import { InputFieldPassword } from 'components/Fields';
import InputField from 'components/Fields/InputField';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { setToken, setTokenTemporarily } from 'services/utils';
import { getToken } from 'services/AuthService';
import { setAuthType } from 'store/user/actions';
import { getAdminEmail } from 'services/UserService';
import Loader from 'components/Loader';

import { IValidateProps } from '../ProfileForm/ProfileForm.types';

import styles from './AuthForm.module.scss';
import { IAuthFields } from './AuthForm.types';

const AuthForm: FC = () => {
  const { t } = useTranslation('auth');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<Record<keyof IAuthFields | 'detail', string[]>>();
  const [isRemember, setIsRemember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const onForgetHandle = () => {
    Modal.info({
      title: t('recoveryModal.title'),
      content: (
        <p>
          {t('recoveryModal.text')} <b>{email}</b>
        </p>
      ),
    });
  };

  const onFinish = async (values: IAuthFields) => {
    try {
      setIsLoading(true);
      const { data } = await getToken(values);
      isRemember ? setToken(data.accept) : setTokenTemporarily(data.accept);
      dispatch(setAuthType(true));
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setErrors(error?.response?.data);
    }
  };

  const getValidateProps = (name: keyof IAuthFields): IValidateProps => {
    const error = errors && errors[name];

    return {
      hasFeedback: true,
      validateStatus: error ? 'error' : '',
      help: error?.join(' '),
    };
  };

  const getEmail = useCallback(async () => {
    try {
      const response = await getAdminEmail();
      setEmail(response.data.email);
    } catch (error: any) {
      setErrors(error?.response?.data);
    }
  }, []);

  useEffect(() => {
    getEmail();
  }, [getEmail]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <InputField
        isAuthPage
        label={t('form.nameLabel')}
        name="username"
        placeholder={t('form.namePlaceholder')}
        {...getValidateProps('username')}
      />
      <InputFieldPassword
        isAuthPage
        label={t('form.passwordLabel')}
        name="password"
        placeholder={t('form.passwordPlaceholder')}
        {...getValidateProps('password')}
      />
      <div className={styles.checkboxContainer}>
        <Checkbox checked={isRemember} onChange={setIsRemember}>
          {t('form.remember')}
        </Checkbox>
      </div>
      {errors?.detail && <div className={styles.errors}>{errors.detail}</div>}

      <div className={styles.buttonContainer}>
        <Button disabled={isLoading} buttonType="default" onClick={onForgetHandle}>
          {t('form.forgotPassword')}
        </Button>

        <Button disabled={isLoading} danger type="submit">
          {isLoading ? (
            <Loader isVisible={isLoading} className={styles.loader} />
          ) : (
            t('form.signIn')
          )}
        </Button>
      </div>
    </Form>
  );
};

export default AuthForm;
