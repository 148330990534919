import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { getAuth } from 'store/user/getters';

import { Paths } from './constants';

const GuestRoute: FC<RouteProps> = (props) => {
  const { children, ...restProps } = props;
  const isAuthorized = useSelector(getAuth);

  if (isAuthorized) {
    return <Redirect to={Paths.home} />;
  }

  return <Route {...restProps}>{children}</Route>;
};

export default GuestRoute;
