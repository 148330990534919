import { LogoutOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Paths } from 'router/constants';
import Language from 'components/Language';
import { logoutUser } from 'store/user/actions';
import { AllApplicationsIcon, MyApplicationsIcon } from 'assets/icons';

import styles from './NavMobile.module.scss';
import { NavMobileProps } from './NavMobile.types';

const NavMobile: FC<NavMobileProps> = ({ isVisible, person, onClose }) => {
  const { t } = useTranslation('header');

  const dispatch = useDispatch();

  const { avatar, position, first_name: firstName, last_name: lastName } = person || {};

  const onLogout = () => {
    dispatch(logoutUser());
    onClose();
  };

  return (
    <Drawer
      className={styles.drawerWrapper}
      placement="right"
      onClose={onClose}
      visible={isVisible}
    >
      <div>
        <div className={styles.languageWrapper}>
          <Language />
        </div>
        <div className={styles.userInfoWrapper}>
          {avatar ? (
            <img className={styles.avatarImg} src={avatar} alt="avatar" />
          ) : (
            <div className={styles.avatarIcon}>
              <UserOutlined />
            </div>
          )}
          <p className={styles.userName}>
            {firstName || ''} {lastName || ''}
          </p>
          <p className={styles.userPosition}>{position || ''}</p>
        </div>

        <div className={styles.nav}>
          <NavLink
            className={styles.navItem}
            to={Paths.home}
            onClick={onClose}
            activeClassName={styles.activeNavItem}
            exact
          >
            <MyApplicationsIcon /> {t('myApps')}
          </NavLink>
          <NavLink
            className={styles.navItem}
            to={Paths.store}
            onClick={onClose}
            activeClassName={styles.activeNavItem}
            exact
          >
            <AllApplicationsIcon /> {t('allApps')}
          </NavLink>
          <NavLink
            className={styles.navItem}
            to={Paths.profile}
            onClick={onClose}
            activeClassName={styles.activeNavItem}
            exact
          >
            <SettingOutlined /> {t('settingsAccount')}
          </NavLink>
        </div>
      </div>
      <button className={styles.logout} type="button" onClick={onLogout}>
        <LogoutOutlined /> {t('exit')}
      </button>
    </Drawer>
  );
};

export default NavMobile;
