import { hasToken } from 'services/utils';

import { Action, ActionType, IUserState } from './types';

const InitialState: IUserState = {
  isAuthorized: hasToken(),
  user: null,
};

export default function userReducer(state = InitialState, action: Action): IUserState {
  switch (action.type) {
    case ActionType.setUserInfo: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case ActionType.setAuthType: {
      return {
        ...state,
        isAuthorized: action.payload,
      };
    }

    case ActionType.logoutUser: {
      return {
        user: null,
        isAuthorized: false,
      };
    }

    default:
      return state;
  }
}
