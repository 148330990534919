import { AxiosRequestConfig, AxiosResponse } from 'axios';

import agent from 'agent';
import { IAdminEmail, IPersonInfo } from 'store/user/types';

export const updateUserInfo = (
  data: Partial<IPersonInfo> | FormData,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<IPersonInfo>> => {
  return agent.PATCH('api/user', data, config);
};

export const getUserInfo = (): Promise<AxiosResponse<IPersonInfo>> => {
  return agent.GET('api/user/');
};

export const getAdminEmail = (): Promise<AxiosResponse<IAdminEmail>> => {
  return agent.GET('api/admin_contact_info/');
};
