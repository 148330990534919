import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Paths } from 'router/constants';
import { logoutUser } from 'store/user/actions';

import { IAvatarProps } from './Avatar.types';
import styles from './Avatar.module.scss';

const Avatar: FC<IAvatarProps> = ({ person }) => {
  const { t } = useTranslation('profile');
  const { avatar, position, first_name: firstName, last_name: lastName } = person || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickHandler = (data: { key: string | number }) => {
    if (data.key === 'logout') {
      dispatch(logoutUser());
    }
    if (data.key === 'profile') {
      history.push(Paths.profile);
    }
  };

  const menu = (
    <Menu onClick={(data) => onClickHandler(data)}>
      <div className={styles.avatar__info}>
        <p className={styles.avatar__username}>
          {firstName} {lastName}
        </p>
        <p className={styles.avatar__position}>{position}</p>
      </div>
      <Menu.Item className={styles.avatarMenu__item} key="profile" icon={<SettingOutlined />}>
        {t('nav.settingsAccount')}
      </Menu.Item>
      <Menu.Item className={styles.avatarMenu__item} key="logout" icon={<LogoutOutlined />}>
        {t('nav.exit')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      className={styles.avatar}
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      arrow
    >
      {avatar ? (
        <img className={styles.avatarImage} src={avatar} alt="avatar" />
      ) : (
        <div className={styles.avatarImage}>
          <UserOutlined />
        </div>
      )}
    </Dropdown>
  );
};

export default Avatar;
