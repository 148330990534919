import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import { getTags } from 'store/card/getters';
import { setTags } from 'store/card/actions';
import { getListTags } from 'services/CardService';
import { getLocale, returnField } from 'services/utils';
import { ITag } from 'components/Card/Card.types';

import styles from './Tags.module.scss';
import { ITagsProps } from './Tags.types';

const Tags: FC<ITagsProps> = ({ activeTagsId, onChangeTagStatus }) => {
  const locale = getLocale();
  const tags = useSelector(getTags);
  const dispatch = useDispatch();

  const [dataTags, setDataTags] = useState<ITag[]>([]);

  useEffect(() => {
    const getTagsProducts = async () => {
      try {
        const { data } = await getListTags();
        setDataTags(data);
      } catch (error) {
        console.error(error);
      }
    };

    getTagsProducts();
  }, []);

  useEffect(() => {
    const tagsArray = dataTags.map((item) => returnField(item.name_ru, item.name_en));
    dispatch(setTags(tagsArray));
  }, [dispatch, locale, dataTags]);

  const tagClasses = (id: number) =>
    classNames(styles.tags__button, {
      [styles.tags__button_active]: activeTagsId.includes(id),
    });

  return (
    <div className={styles.tags__container}>
      {tags.map((item, index) => (
        <Button
          key={item || index}
          onClick={() => onChangeTagStatus(index)}
          className={tagClasses(index)}
          buttonType="text"
        >
          {item}
        </Button>
      ))}
    </div>
  );
};

export default Tags;
