const primary = window._env_.REACT_APP_PRIMARY_LANGUAGE ?? process.env.REACT_APP_PRIMARY_LANGUAGE;
const secondary =
  window._env_.REACT_APP_SECONDARY_LANGUAGE ?? process.env.REACT_APP_SECONDARY_LANGUAGE;

export function isValidLanguage(language: unknown): language is 'ru' | 'en' {
  return typeof language === 'string' && ['ru', 'en'].includes(language);
}

export const PRIMARY_LANGUAGE = isValidLanguage(primary) ? primary : null;
export const SECONDARY_LANGUAGE = isValidLanguage(secondary) ? secondary : null;

export const DEFAULT_PRIMARY_LANGUAGE = 'ru';
export const DEFAULT_SECONDARY_LANGUAGE = 'en';
