import { CameraFilled } from '@ant-design/icons';
import { Form, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import React, { FC } from 'react';
import { UploadProps } from 'antd/lib/upload/interface';

import styles from './Fields.module.scss';
import { IUploadFieldProps } from './Fields.types';

type RcCustomRequestOptions<T = object> = Parameters<
  Exclude<UploadProps<T>['customRequest'], undefined>
>[0];

const UploadField: FC<IUploadFieldProps> = ({
  name,
  avatar,
  setAvatar,
  disabled,
  defaultAvatar,
  hasFeedback,
  validateStatus,
  help,
}) => {
  const onChangeFile = (data: UploadChangeParam) => {
    const {
      file: { originFileObj },
    } = data;
    setAvatar(URL.createObjectURL(originFileObj));
  };

  const customRequest = ({ onSuccess }: RcCustomRequestOptions) => {
    // TODO: workaround for canceling extra request
    setTimeout(() => {
      const xhr = new XMLHttpRequest();
      if (onSuccess) {
        onSuccess(() => {}, xhr);
      }
    }, 0);
  };

  return (
    <Form.Item
      hasFeedback={hasFeedback}
      help={help}
      validateStatus={validateStatus}
      name={name}
      noStyle
    >
      <Upload.Dragger
        disabled={disabled}
        accept="image/*"
        customRequest={customRequest}
        showUploadList={false}
        style={{ backgroundImage: `url(${avatar || defaultAvatar})` }}
        className={styles.upload}
        onChange={onChangeFile}
      >
        {!disabled && (
          <div className={styles.upload__icon}>
            <CameraFilled />
          </div>
        )}
        <div className={styles.upload__error}>{help}</div>
      </Upload.Dragger>
    </Form.Item>
  );
};

export default UploadField;
