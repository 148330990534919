import { ICard } from 'components/Card/Card.types';

export interface ICardState {
  cards: ICard[];
  colorMode: string;
  tags: string[];
}

export enum ActionType {
  changeFavoriteStatus = 'CHANGE_FAVORITE_STATUS',
  removeCard = 'REMOVE_CARD',
  changeColorMode = 'CHANGE_COLOR_MODE',
  setTags = 'SET_TAGS',
  setCards = 'SET_CARDS',
}

export type Action =
  | { type: ActionType.changeFavoriteStatus; payload: number }
  | { type: ActionType.changeColorMode; payload: string }
  | { type: ActionType.setTags; payload: string[] }
  | { type: ActionType.setCards; payload: ICard[] };
