import React, { FC } from 'react';
import { Button as ButtonAnt } from 'antd';
import classNames from 'classnames';

import { IButtonProps } from './Button.types';
import styles from './Button.module.scss';

const Button: FC<IButtonProps> = ({
  type = 'button',
  buttonType = 'primary',
  className,
  children,
  ...rest
}) => {
  const buttonClasses = classNames(styles.button, className);

  return (
    <>
      <ButtonAnt {...rest} type={buttonType} htmlType={type} className={buttonClasses}>
        {children}
      </ButtonAnt>
    </>
  );
};

export default Button;
