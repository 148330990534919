import React, { FC } from 'react';

import styles from './layouts.module.scss';
import { IProfileSectionLayoutProps } from './layout.types';
import { TitleWrapper } from './layouts.styled';

const ProfileSectionLayout: FC<IProfileSectionLayoutProps> = ({ children, name }) => {
  return (
    <div className={styles.profileSectionLayout}>
      <TitleWrapper>
        <h1 className={styles.profileSectionLayout__title}>{name}</h1>
      </TitleWrapper>
      <div className={styles.profileSectionLayout__container}>{children}</div>
    </div>
  );
};

export default ProfileSectionLayout;
