import React, { FC } from 'react';

import { ICardLayoutProps } from './layout.types';
import styles from './layouts.module.scss';
import { TitleWrapper } from './layouts.styled';

const CardLayout: FC<ICardLayoutProps> = ({ children, name }) => (
  <div className={styles.cardLayout}>
    <TitleWrapper>
      <h1 className={styles.cardLayout__title}>{name}</h1>
    </TitleWrapper>
    <div className={styles.cardLayout__cards}>{children}</div>
  </div>
);

export default CardLayout;
