import styled from 'styled-components';

export const CardWrapper = styled.a`
  display: block;
  background: ${({ theme }) => theme.background};
  padding: 24px;
  max-width: 330px;
  width: 100%;
  border-radius: 4px;
  padding-right: 13px;
  color: ${({ theme }) => theme.text};
  transition: all 0.25s linear;

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
`;

export const ParagraphWrapper = styled.div`
  font-weight: 400;
  margin-bottom: 0;
  color: ${({ theme }) => `${theme.descriptionColor}`};
  transition: all 0.25s linear;
`;

export const IconWrapper = styled.span`
  svg {
    path {
      fill: ${({ theme }) => theme.primary};
      transition: all 0.25s linear;
    }
  }
`;
