import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';

import { AuthPage, MyApplicationsPage, ProfilePage, StoreAppsPage } from 'pages';
import { darkTheme, lightTheme } from 'styles/theme';
import { getColorMode } from 'store/card/getters';

import history from './History';
import PrivateRoute from './PrivateRoute';
import GuestRoute from './GuestRoute';
import { Paths } from './constants';

const AppRouter: React.FC = () => {
  const colorScheme = useSelector(getColorMode);

  return (
    <ThemeProvider theme={colorScheme === 'light' ? lightTheme : darkTheme}>
      <Router history={history}>
        <Switch>
          <GuestRoute exact path={Paths.auth} component={AuthPage} />

          <PrivateRoute exact path={Paths.home} component={MyApplicationsPage} />
          <PrivateRoute exact path={Paths.store} component={StoreAppsPage} />
          <PrivateRoute exact path={Paths.profile} component={ProfilePage} />

          <Route component={() => <>404: Page not found</>} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
