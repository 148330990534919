import React, { FC } from 'react';
import { Checkbox as CheckboxAnt } from 'antd';

import { ICheckboxProps } from './Checkbox.types';
import styles from './Checkbox.module.scss';

const Checkbox: FC<ICheckboxProps> = ({ children, checked, onChange }) => {
  return (
    <CheckboxAnt
      className={styles.checkboxContainer}
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
    >
      <span className={styles.checkboxLabel}>{children}</span>
    </CheckboxAnt>
  );
};

export default Checkbox;
