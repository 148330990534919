import React, { FC } from 'react';

import Header from 'components/Header';
import SearchBar from 'components/SearchBar';

import styles from './layouts.module.scss';
import { IMainLayoutProps } from './layout.types';
import { LayoutWrapper } from './layouts.styled';

const MainLayout: FC<IMainLayoutProps> = ({ children, setCards, hasTags, cards }) => {
  return (
    <>
      <Header />
      <LayoutWrapper>
        <div className={styles.mainLayout}>
          {!!setCards && !!cards && (
            <SearchBar cards={cards} setCards={setCards} hasTags={hasTags} />
          )}
          {children}
        </div>
      </LayoutWrapper>
    </>
  );
};

export default MainLayout;
