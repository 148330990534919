import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Card from 'components/Card';
import { ICard, ICardType } from 'components/Card/Card.types';
import { CardLayout, MainLayout } from 'layouts';
import { getCards as getDefaultCards } from 'store/card/getters';
import { setCardsAction } from 'store/card/actions';
import { getListProducts } from 'services/CardService';
import { updateUserInfo } from 'services/UserService';

const MyApplicationsPage: FC = () => {
  const { t } = useTranslation('applications');
  const defaultCards = useSelector(getDefaultCards);
  const [cards, setCards] = useState<ICard[]>([]);
  const dispatch = useDispatch();

  const getCards = useCallback(async () => {
    try {
      const { data } = await getListProducts();
      setCards(data);
      dispatch(setCardsAction(data));
    } catch {
      setCards([]);
      dispatch(setCardsAction([]));
    }
  }, [dispatch]);

  const favoritesCards = cards.filter((item) => item.is_favorite);
  const ownCards = cards.filter((item) => item.is_available);

  const onAddFavorites = async (idCard: number) => {
    const isIncludeFavorites = favoritesCards.map((item) => item.id).includes(idCard);
    const idsFavorites = favoritesCards.map((item) => item.id);
    try {
      if (isIncludeFavorites) {
        await updateUserInfo({
          favorite_products: idsFavorites.filter((item) => item !== idCard),
        });
      } else {
        await updateUserInfo({
          favorite_products: [...idsFavorites, idCard],
        });
      }
    } finally {
      setCards((prevState) =>
        prevState.map((item) =>
          idCard === item.id ? { ...item, is_favorite: !item.is_favorite } : item
        )
      );
    }
  };

  useEffect(() => {
    setCards(defaultCards);
  }, [defaultCards]);

  useEffect(() => {
    getCards();
  }, [getCards]);

  return (
    <>
      <MainLayout setCards={setCards}>
        {favoritesCards.length > 0 && (
          <CardLayout name={t('titles.favorite')}>
            {favoritesCards.map((item, index) => (
              <Card
                onAddFavorites={onAddFavorites}
                cardType={ICardType.own}
                key={String(index)}
                card={item}
              />
            ))}
          </CardLayout>
        )}
        {cards.length > 0 && (
          <CardLayout name={t('titles.myApps')}>
            {ownCards.map((item, index) => (
              <Card
                onAddFavorites={onAddFavorites}
                cardType={ICardType.own}
                key={String(index)}
                card={item}
              />
            ))}
          </CardLayout>
        )}
      </MainLayout>
    </>
  );
};

export default MyApplicationsPage;
