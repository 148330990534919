import React, { FC } from 'react';
import { Form, Input } from 'antd';
import classNames from 'classnames';

import 'antd/dist/antd.css';

import LabelField from 'components/LabelField/LabelField';

import { IInputFieldProps } from './Fields.types';
import styles from './Fields.module.scss';
import { InputWrapper } from './Fields.styled';

const InputField: FC<IInputFieldProps> = ({
  label,
  placeholder,
  className,
  name,
  isAuthPage,
  hasFeedback,
  validateStatus,
  help,
  ...rest
}) => {
  const input = (
    <Form.Item
      hasFeedback={hasFeedback}
      help={help}
      validateStatus={validateStatus}
      label={<LabelField>{label}</LabelField>}
      name={name}
    >
      <Input {...rest} className={classNames(styles.input, className)} placeholder={placeholder} />
    </Form.Item>
  );

  return isAuthPage ? input : <InputWrapper>{input}</InputWrapper>;
};

export default InputField;
