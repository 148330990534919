import axios from 'axios';

import history from 'router/History';
import { Paths } from 'router/constants';
import { getLocale, getToken, hasToken, removeToken } from 'services/utils';

import { API_URL } from './constants';

const HTTP = axios.create({ baseURL: API_URL });

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : 408;
    if (status >= 500) {
      window.console.error(error.toString());
    }
    if (status === 401) {
      removeToken();
      history.push(Paths.auth);
    }

    return Promise.reject(error);
  }
);

HTTP.interceptors.request.use(
  (config) => {
    const updConfig = { ...config };

    if (hasToken()) {
      updConfig.headers.Authorization = `Bearer ${getToken()}`;
    }

    updConfig.headers['Accept-Language'] = getLocale();

    return updConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default HTTP;
