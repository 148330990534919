import { ICardState, Action, ActionType } from './types';

const InitialState: ICardState = {
  cards: [],
  colorMode: localStorage.getItem('theme') || 'light',
  tags: [],
};

export default function cardReducer(state = InitialState, action: Action): ICardState {
  switch (action.type) {
    case ActionType.changeFavoriteStatus: {
      const updatedCards = state.cards.map((item) => {
        if (action.payload === item.id) {
          return {
            ...item,
            is_favorite: !item.is_favorite,
          };
        }

        return item;
      });

      return {
        ...state,
        cards: updatedCards,
      };
    }

    case ActionType.changeColorMode: {
      return {
        ...state,
        colorMode: action.payload,
      };
    }

    case ActionType.setTags: {
      return {
        ...state,
        tags: action.payload,
      };
    }

    case ActionType.setCards: {
      return {
        ...state,
        cards: action.payload,
      };
    }

    default:
      return state;
  }
}
