import React, { FC, useState } from 'react';

import { MainLayout } from 'layouts';
import ProfileForm from 'components/forms/ProfileForm';

import { Wrapper } from './ProfilePage.styled';

const ProfilePage: FC = () => {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <>
      <MainLayout>
        <Wrapper>
          <ProfileForm isEditMode={isEditMode} onChangeMode={setIsEditMode} />
        </Wrapper>
      </MainLayout>
    </>
  );
};

export default ProfilePage;
