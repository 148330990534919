import i18next from 'i18next';

import { ICard, ITag } from 'components/Card/Card.types';

const DEFAULT_LANGUAGE = 'ru';

export const setToken = (token: string) => localStorage.setItem('jwt', token);

export const setTokenTemporarily = (token: string) => sessionStorage.setItem('jwt', token);

export const hasToken = () => !!getToken();

export const removeToken = () => {
  localStorage.removeItem('jwt');
  sessionStorage.removeItem('jwt');
};

export const getToken = () => {
  const sessionToken = sessionStorage.getItem('jwt');
  if (sessionToken !== null) {
    return sessionToken;
  }
  return localStorage.getItem('jwt');
};

export function getLocale(): string {
  try {
    return window.localStorage.getItem('i18nextLng') || i18next.language || DEFAULT_LANGUAGE;
  } catch {
    return DEFAULT_LANGUAGE;
  }
}

export const returnField = (ru: string, en: string) => {
  const locale = getLocale();
  return locale === 'ru' || locale === 'ru-RU' ? ru : en;
};

export const getCardsWithLocaleTags = (cards: ICard[]) => {
  const locale = getLocale();
  return cards.map((itemCard: ICard) => {
    const filters = itemCard.tags.map((itemTag: ITag) =>
      locale === 'ru' || locale === 'ru-RU' ? itemTag.name_ru : itemTag.name_en
    );

    return {
      ...itemCard,
      filters,
    };
  });
};
