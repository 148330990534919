import { ICard } from 'components/Card/Card.types';

import { ActionType, Action } from './types';

export const changeStatusFavoriteAction = (cardId: number): Action => ({
  type: ActionType.changeFavoriteStatus,
  payload: cardId,
});

export const changeColorMode = (mode: string): Action => ({
  type: ActionType.changeColorMode,
  payload: mode,
});

export const setTags = (tags: string[]): Action => ({
  type: ActionType.setTags,
  payload: tags,
});

export const setCardsAction = (tags: ICard[]): Action => ({
  type: ActionType.setCards,
  payload: tags,
});
