import * as JsSearch from 'js-search';

import { ICard } from 'components/Card/Card.types';

export const getSearchDefaultInstance = (cards: ICard[]) => {
  const search = new JsSearch.Search('id');

  search.addIndex('filters');
  search.addIndex('name_ru');
  search.addIndex('name_en');
  search.addIndex('description_ru');
  search.addIndex('description_en');
  search.addDocuments(cards);

  return search;
};

export const getSearchTagsInstance = (cards: ICard[]) => {
  const search = new JsSearch.Search('id');
  search.addIndex('filters');
  search.addDocuments(cards);

  return search;
};
