import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ICard } from 'components/Card/Card.types';
import Tags from 'components/Tags';
import { getCards, getTags } from 'store/card/getters';
import { prepareTagsSearching } from 'components/Tags/Tags.utils';
import { getCardsWithLocaleTags } from 'services/utils';

import { ISearchBarProps } from './SearchBar.types';
import styles from './SearchBar.module.scss';
import { SearchWrapper } from './SearchBar.styled';
import { getSearchDefaultInstance, getSearchTagsInstance } from './SearchBar.utils';

const SearchBar: FC<ISearchBarProps> = ({ cards, setCards, hasTags }) => {
  const { t } = useTranslation('search');
  const [searchValue, setSearchValue] = useState('');
  const [activeTagsId, setActiveTagsId] = useState<number[]>([]);
  const gCards = useSelector(getCards);
  const allCards = getCardsWithLocaleTags(gCards);
  const tags = useSelector(getTags);

  const initialCards = useMemo(
    () => (hasTags ? cards : cards.filter((item) => item.is_available)),
    [cards, hasTags]
  );

  const changeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);

    if (value.length > 0) {
      const searchDefault = getSearchDefaultInstance(cards);
      setCards(searchDefault.search(value) as ICard[]);
    }
    if (value.length === 0) {
      if (activeTagsId.length > 0) {
        const searchByTags = getSearchTagsInstance(allCards);
        const resultCards = searchByTags.search(prepareTagsSearching(activeTagsId, tags));
        setCards(resultCards as ICard[]);
      } else {
        setCards(allCards);
      }
    }
  };

  const onChangeTagStatus = (index: number) => {
    if (activeTagsId.includes(index)) {
      setActiveTagsId((prevState) => {
        const filteredCards = prevState.filter((id) => id !== index);

        if (filteredCards.length === 0) {
          if (searchValue.length > 0) {
            const searchDefault = getSearchDefaultInstance(allCards);
            setCards(searchDefault.search(searchValue) as ICard[]);
          } else {
            setCards(allCards);
          }
          return [];
        }

        const searchByTags = getSearchTagsInstance(allCards);
        setCards(searchByTags.search(prepareTagsSearching(filteredCards, tags)) as ICard[]);
        return filteredCards;
      });
    } else {
      setActiveTagsId((prevState) => {
        const searchByTags = getSearchTagsInstance(initialCards);

        const resultCards = searchByTags.search(prepareTagsSearching([...prevState, index], tags));
        setCards(resultCards as ICard[]);
        return [...prevState, index];
      });
    }
  };

  return (
    <SearchWrapper>
      <div className={styles.searchBar}>
        <Input
          placeholder={t('placeholder')}
          prefix={<SearchOutlined />}
          value={searchValue}
          onChange={changeSearchValue}
        />
        {hasTags && (
          <Tags
            activeTagsId={activeTagsId}
            setActiveTagsId={setActiveTagsId}
            setCards={setCards}
            initialCards={initialCards}
            onChangeTagStatus={onChangeTagStatus}
          />
        )}
      </div>
    </SearchWrapper>
  );
};

export default SearchBar;
