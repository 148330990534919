import { removeToken } from 'services/utils';

import { ActionType, Action, IPersonInfo } from './types';

export const setAuthType = (authType: boolean): Action => ({
  type: ActionType.setAuthType,
  payload: authType,
});

export const logoutUser = (): Action => {
  removeToken();

  return {
    type: ActionType.logoutUser,
  };
};

export const setUserInfoAction = (person: IPersonInfo | null): Action => ({
  type: ActionType.setUserInfo,
  payload: person,
});
