import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  .ant-modal-header,
  .ant-modal-content {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    transition: all 0.25s linear;

    button {
      color: ${({ theme }) => theme.text};
    }
  }
  .ant-modal-title {
    color: ${({ theme }) => theme.text};
  }
  .ant-modal-header,
  .ant-modal-footer {
    border-color: ${({ theme }) => theme.inputBorder};
  }
`;
