import classNames from 'classnames';
import React, { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { ILoaderProps } from './Loader.types';
import styles from './Loader.module.scss';

const Loader: FC<ILoaderProps> = ({ className, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className={classNames(styles.loader, className)}>
      <LoadingOutlined />
    </div>
  );
};

export default Loader;
