export const lightTheme = {
  background: '#ffffff',
  text: '#111111',
  descriptionColor: '#808f98',
  primary: '#003B5C',
  title: '#5892b2',
  backgroundLayout: '#e2e2e2',
  input: '#ffffff',
  inputBorder: '#ccd8de',
};

export const darkTheme = {
  background: '#004264',
  text: '#ffffff',
  descriptionColor: '#99b3c1',
  primary: '#5892b2',
  title: '#003B5C',
  backgroundLayout: '#012E45',
  input: '#003A58',
  inputBorder: '#588eab',
};
