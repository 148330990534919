import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AuthForm from 'components/forms/AuthForm';
import Language from 'components/Language';
import AuthLogo from 'components/Logo/AuthLogo';

import styles from './AuthPage.module.scss';

const AuthPage: FC = () => {
  const { t } = useTranslation('auth');

  return (
    <div className={styles.page__container}>
      <div className={styles.page__form}>
        <div className={styles.page__logo}>
          <AuthLogo />
          <Language isOnAuthPage />
        </div>
        <h1 className={styles.page__welcome}>{t('welcome')}</h1>
        <AuthForm />
      </div>
      <div className={styles.page__image} />
    </div>
  );
};

export default AuthPage;
