export interface IPersonInfo {
  first_name: string;
  last_name: string;
  position: string;
  email: string;
  phone: string;
  avatar: string;
  favorite_products: number[];
}
export interface IUserState {
  isAuthorized: boolean;
  user: IPersonInfo | null;
}

export enum ActionType {
  setAuthType = 'SET_AUTH_TYPE',
  setUserInfo = 'SET_USER_INFO',
  logoutUser = 'LOGOUT_USER',
}

export type Action =
  | {
      type: ActionType.setAuthType;
      payload: boolean;
    }
  | {
      type: ActionType.setUserInfo;
      payload: IPersonInfo | null;
    }
  | { type: ActionType.logoutUser };

export interface IAdminEmail {
  email: string;
}
