import { Form } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import InputField from 'components/Fields/InputField';
import ProfileSectionLayout from 'layouts/ProfileSectionLayout';
import UploadField from 'components/Fields/UploadField';
import Button from 'components/Button';
import { updateUserInfo } from 'services/UserService';
import { getPersonInfo } from 'store/user/getters';
import Loader from 'components/Loader';
import { IPersonInfo } from 'store/user/types';
import { setUserInfoAction } from 'store/user/actions';

import { IProfileFormProps, IValidateProps } from './ProfileForm.types';
import {
  Avatar,
  ButtonsWrapper,
  FCS,
  InputWrapper,
  Person,
  PictureContainer,
} from './ProfileForm.styled';

const ProfileForm: FC<IProfileFormProps> = ({ onChangeMode, isEditMode }) => {
  const { t } = useTranslation('profile');
  const [form] = Form.useForm();
  const person = useSelector(getPersonInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<keyof IPersonInfo, string[]>>();

  const [profileAvatar, setProfileAvatar] = useState<string>();

  const dispatch = useDispatch();

  const onSubmit = async (values: IPersonInfo) => {
    try {
      setIsLoading(true);
      const { avatar } = form.getFieldsValue();
      const formData = new FormData();
      const isExist = !!avatar?.file;
      Object.entries(values).forEach((item) => {
        if (item[0] === 'avatar') {
          if (isExist) {
            formData.set('avatar', avatar.file.originFileObj);
          }
        } else {
          formData.set(item[0], item[1]);
        }
      });

      const response = await updateUserInfo(formData);

      dispatch(setUserInfoAction(response.data));

      onChangeMode(false);
      setErrors(undefined);
    } catch (error: any) {
      setErrors(error?.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const profileFormInputProps = isEditMode
    ? null
    : {
        bordered: !isEditMode,
        disabled: !isEditMode,
      };

  const getValidateProps = (name: keyof IPersonInfo): IValidateProps => {
    const error = errors && errors[name];

    return {
      hasFeedback: true,
      validateStatus: error ? 'error' : '',
      help: error?.join(' '),
    };
  };

  const cancelEditing = () => {
    onChangeMode(false);
    form.setFieldsValue(person);
    setProfileAvatar(undefined);
  };

  return (
    <>
      {person ? (
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          initialValues={{ ...(person || {}) }}
        >
          <ProfileSectionLayout name={t('personalTitle')}>
            <Person>
              <Avatar>
                {!profileFormInputProps?.disabled ? (
                  <UploadField
                    name="avatar"
                    {...profileFormInputProps}
                    defaultAvatar={person?.avatar}
                    avatar={profileAvatar}
                    setAvatar={setProfileAvatar}
                    {...getValidateProps('avatar')}
                  />
                ) : (
                  <PictureContainer backgroundImage={`url(${profileAvatar || person?.avatar})`} />
                )}
              </Avatar>
              <FCS>
                <InputWrapper>
                  <InputField
                    {...profileFormInputProps}
                    {...getValidateProps('last_name')}
                    label={t('form.surnameLabel')}
                    name="last_name"
                    placeholder={t('form.surnamePlaceholder')}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    {...profileFormInputProps}
                    {...getValidateProps('first_name')}
                    label={t('form.nameLabel')}
                    name="first_name"
                    placeholder={t('form.namePlaceholder')}
                  />
                </InputWrapper>
              </FCS>
            </Person>
          </ProfileSectionLayout>
          <ProfileSectionLayout name={t('profileTitle')}>
            <InputWrapper>
              <InputField
                {...profileFormInputProps}
                {...getValidateProps('position')}
                label={t('form.positionLabel')}
                name="position"
                placeholder={t('form.positionPlaceholder')}
              />
            </InputWrapper>
          </ProfileSectionLayout>
          <ProfileSectionLayout name={t('contactsTitle')}>
            <InputWrapper>
              <InputField
                {...profileFormInputProps}
                {...getValidateProps('email')}
                label={t('form.emailLabel')}
                name="email"
                placeholder={t('form.emailPlaceholder')}
              />
            </InputWrapper>
            <InputWrapper>
              <InputField
                {...profileFormInputProps}
                {...getValidateProps('phone')}
                label={t('form.phoneLabel')}
                name="phone"
                placeholder={t('form.phonePlaceholder')}
              />
            </InputWrapper>
          </ProfileSectionLayout>
          <ButtonsWrapper>
            {isEditMode ? (
              <>
                <Button onClick={cancelEditing}>{t('form.cancel')}</Button>
                <Button type="submit" danger>
                  {t('form.save')}
                </Button>
              </>
            ) : (
              <Button type="button" onClick={() => onChangeMode(true)}>
                {t('form.edit')}
              </Button>
            )}
          </ButtonsWrapper>
        </Form>
      ) : (
        <Loader isVisible={isLoading} />
      )}
    </>
  );
};

export default ProfileForm;
