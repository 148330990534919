import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import 'antd/dist/antd.css';

import LabelField from 'components/LabelField/LabelField';

import { IInputFieldProps } from './Fields.types';
import styles from './Fields.module.scss';
import { InputWrapper } from './Fields.styled';

const InputFieldPassword: FC<IInputFieldProps> = ({
  label,
  placeholder,
  name,
  isAuthPage,
  hasFeedback,
  validateStatus,
  help,
}) => {
  const input = (
    <Form.Item
      label={<LabelField>{label}</LabelField>}
      name={name}
      hasFeedback={hasFeedback}
      help={help}
      validateStatus={validateStatus}
    >
      <Input.Password
        className={styles.input}
        placeholder={placeholder}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
    </Form.Item>
  );

  return isAuthPage ? input : <InputWrapper>{input}</InputWrapper>;
};

export default InputFieldPassword;
