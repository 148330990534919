import { IState } from '../interfaces';

import { ICardState } from './types';

const getState = (state: IState): ICardState => state.cardReducer;

export const getCards = (state: IState) => {
  const cardState = getState(state);
  return cardState.cards;
};

export const getColorMode = (state: IState) => {
  const cardState = getState(state);
  return cardState.colorMode;
};

export const getTags = (state: IState) => {
  const cardState = getState(state);
  return cardState.tags;
};
