import styled from 'styled-components';

export const SearchWrapper = styled.div`
  .ant-input-affix-wrapper,
  input {
    background: ${({ theme }) => theme.input};
    transition: all 0.25s linear;
  }

  input {
    color: ${({ theme }) => theme.text};
  }

  span {
    border-color: ${({ theme }) => theme.inputBorder};
  }
`;
