import styled from 'styled-components';

export const Person = styled.div`
  display: flex;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const Avatar = styled.div`
  width: 172px;
  height: 172px;

  & .ant-form-item-control-input-content {
    height: 172px;
    min-height: 172px;

    @media screen and (max-width: 480px) {
      height: calc(100vw - 80px);
      min-height: calc(100vw - 80px);
    }
  }

  @media screen and (max-width: 480px) {
    width: calc(100vw - 80px);
    height: calc(100vw - 80px);
  }
`;

export const FCS = styled.div`
  margin-left: 28px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    margin-left: 0;
    margin-top: 20px;
  }

  & .ant-col.ant-form-item-label {
    padding-bottom: 4px !important;
  }

  & span {
    font-size: 16px;
    line-height: 1;

    @media screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 1;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;

  div {
    width: 100%;
  }

  input {
    &:disabled {
      background-color: $white-color;
      color: $black-color;
      border: none;
      font-size: 16px;
      padding-left: 0;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    @extend .fontBase;
    font-weight: 400px;
    color: $white-color;
    height: 44px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 8px;

    button {
      width: 100%;
    }
  }
`;

export const PictureContainer = styled.div<{ backgroundImage: string }>`
  background-image: ${({ backgroundImage }) => backgroundImage};
  background-size: cover;
  width: 100%;
  height: 100%;
`;
