import React, { FC, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { getLocale } from 'services/utils';
import { PRIMARY_LANGUAGE, SECONDARY_LANGUAGE } from 'constants/language';

import styles from './Language.module.scss';
import { ILanguageProps } from './Language.types';

const languageLabelMap: Record<string, string> = {
  ru: 'RU',
  en: 'EN',
};

const languageMap: Record<string, string> = {
  ru: 'ru',
  'ru-RU': 'ru',
  en: 'en',
  'en-US': 'en',
};

function getLanguageOptions(): { key: string; label: string }[] {
  try {
    const primaryLanguage = PRIMARY_LANGUAGE && languageLabelMap[PRIMARY_LANGUAGE];
    const secondaryLanguage = SECONDARY_LANGUAGE && languageLabelMap[SECONDARY_LANGUAGE];

    if (primaryLanguage && secondaryLanguage && PRIMARY_LANGUAGE && SECONDARY_LANGUAGE) {
      return [
        { key: PRIMARY_LANGUAGE, label: primaryLanguage },
        { key: SECONDARY_LANGUAGE, label: secondaryLanguage },
      ];
    }

    if (primaryLanguage && PRIMARY_LANGUAGE) {
      return [{ key: PRIMARY_LANGUAGE, label: primaryLanguage }];
    }

    if (secondaryLanguage && SECONDARY_LANGUAGE) {
      return [{ key: SECONDARY_LANGUAGE, label: secondaryLanguage }];
    }

    return [
      { key: 'ru', label: 'RU' },
      { key: 'en', label: 'EN' },
    ];
  } catch (e) {
    console.error(e);

    return [
      { key: 'ru', label: 'RU' },
      { key: 'en', label: 'EN' },
    ];
  }
}

const Language: FC<ILanguageProps> = ({ isOnAuthPage, className }) => {
  const { i18n } = useTranslation();
  const language = getLocale();

  const options = useRef(getLanguageOptions());

  const langLabel = languageLabelMap?.[languageMap?.[language]];

  const onClickHandler: MenuProps['onClick'] = (data) => {
    i18n.changeLanguage(String(data.key));
  };

  if (options.current.length <= 1) {
    return null;
  }

  const menu = (
    <Menu onClick={onClickHandler}>
      {options.current.map(({ key, label }) => (
        <Menu.Item key={key} className={styles.language__item}>
          {label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      className={classNames(styles.language, className, { [styles.language_white]: !isOnAuthPage })}
    >
      <div>
        {langLabel} <DownOutlined />
      </div>
    </Dropdown>
  );
};

export default Language;
