import styled from 'styled-components';

export const InputWrapper = styled.div`
  .ant-form-item-label label > span {
    color: ${({ theme }) => theme.text};
  }

  .ant-form-item {
    width: 100%;
    transition: all 0.25s linear;
  }

  .ant-input-password {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.input};
    .ant-input-suffix svg {
      fill: ${({ theme }) => theme.text};
    }
  }

  input {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.input};

    &:disabled {
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.text};
    }
  }

  span {
    border-color: ${({ theme }) => theme.inputBorder};
  }
`;
