import auth from './auth.json';
import applications from './applications.json';
import profile from './profile.json';
import search from './search.json';
import header from './header.json';

export default {
  auth,
  applications,
  profile,
  search,
  header,
};
