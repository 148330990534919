import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeColorMode } from 'store/card/actions';

export const useColorScheme = () => {
  const [colorScheme, setColorScheme] = useState(localStorage.getItem('theme') || 'light');
  const dispatch = useDispatch();

  const changeColor = (theme: string) => {
    setColorScheme(theme);
    dispatch(changeColorMode(theme));
    localStorage.setItem('theme', theme);
  };

  const toggleTheme = () => {
    colorScheme === 'light' ? changeColor('dark') : changeColor('light');
  };

  return { colorScheme, toggleTheme };
};
